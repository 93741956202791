import { AfterViewInit, Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { nanoid } from 'nanoid';
import { MessageService } from 'primeng/api';
import { OtpService } from 'src/app/services/otp.service';
import { GlobalServicesService } from '../services/global-services.service';
import { LoginService } from '../services/login.service';
import { InAppOauthService } from '../services/oauth.service';

export interface SSOAuthConfig {
  authEndpoint: string;
  appName: string;
  responseType: string;
  redirectUri: string;
  clientId: string;
  userInfoEndpoint: string;
  scope: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() companyLogo: string = '';

  passwordShow: boolean = false;
  height: string = window.innerHeight.toString() + 'px';
  isLoading: boolean = false;
  ssoLoginSpinner: boolean = false;
  emailPattern: any = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,24}$/;
  loginSection = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
    password: new FormControl('', [Validators.required, Validators.minLength(1)])
  });
  invalidCred: boolean = false;
  s3Domain: string = 'https://s3.amazonaws.com';
  bucketName: string = this.globalService.bucketName;
  sourceImg: string = `${this.s3Domain}/${this.bucketName}/general-settings/company-details/company-logo/default-logo.png`;
  coverImage: string = '';
  loginEmail: string = '';
  clicked: boolean = false;
  otpRequired: boolean = false;
  otpVerified: boolean = false;
  schoolData: any;
  schoolDetails: any;
  schoolCode: any;
  routingDetails: any;
  userDetails: any;
  privilegeData: any;
  isSSOEnabled: boolean = false;
  ssoAuthConfig: any = [];

  constructor(
    private loginService: LoginService, 
    public globalService: GlobalServicesService, 
    private messageService: MessageService, 
    private inAppOauthService: InAppOauthService,
    private otpService: OtpService,
    private route: ActivatedRoute
    ) {
      this.schoolData = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '')
      : '';
      this.schoolCode = this.schoolData.school_code;
      this.schoolDetails = localStorage.getItem('schoolDetails') ? JSON.parse(localStorage.getItem('schoolDetails') || '')[0] : '';
      this.ssoAuthConfig = this.schoolDetails.ssoConfig;
  }

  ngOnInit(): void {
    console.log(this.route.snapshot.data['data']);
  }

  async ngAfterViewInit(): Promise<void> {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      this.isSSOEnabled = queryParams.get('sso') === 'true'; // temporary handle for sso enabled this has to be from database

    } catch (error) {
      console.error('Error during ngAfterViewInit:', error);
    }
  }
  
  ngOnChanges(): void {
    this.schoolDetails = localStorage.getItem('schoolDetails') ? JSON.parse(localStorage.getItem('schoolDetails') || '')[0] : '';
    this.ssoAuthConfig = this.schoolDetails.ssoConfig;
    this.coverImage = localStorage.getItem('schoolDetails')
    ? JSON.parse(localStorage.getItem('schoolDetails') || '')[0].company?.loginBanner
    : '';
    if(!this.coverImage && !this.coverImage?.length)
    {
      this.coverImage = `${this.s3Domain}/${this.bucketName}/general-settings/company-details/company-logo/default-cover.jpg`;
    }

    if (this.companyLogo.length) {
      this.sourceImg = this.companyLogo;
    }
  }

  onResize() {
    this.height = "100vh";
  }

  toggleShowHidePwd() {
    let x: any = document.getElementsByClassName("passwordInput")[0];
    if (x.type === "password") {
      x.type = "text";
      this.passwordShow = true;
    } else {
      x.type = "password";
      this.passwordShow = false;
    }
  }

  signIn() {
    this.clicked = true;
    this.loginSection.get('email')?.markAsTouched();
    this.loginSection.get('password')?.markAsTouched();
    if (this.loginSection.valid) {
      this.isLoading = true;
      let payload: any = {
        email: this.loginSection.value.email,
        password: this.loginSection.value.password
      }
      this.loginService.login(payload).subscribe((response) => {
        this.isLoading = false;
        let data = response.data;
        if (data && data !== 'Email or password is invalid') {
          let userDetails: any = {
            email: data.email,
            id: data.userId,
            name: data.userDetails.name,
          }
          this.userDetails = userDetails;
          let roleType: any = data.userDetails.userType;
          this.globalService.getUserPrivileges(roleType).subscribe((response) => {
            if (response.status === 200) {
              this.clicked = false;
              this.loginEmail = this.loginSection.value.email;
              let payload = {
                verificationType: '2FA',
              }
              this.otpService.sendOtp(this.loginEmail, nanoid(10), payload).subscribe((res: any) => {
                this.routingDetails = data;
                this.otpRequired = true;
                this.privilegeData = response.data;
              })
            }
            else {
              this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Something Went Wrong!' });
            }
          })
        }
        else {
          this.invalidCred = true;
        }
      })
    }
  }

  ssoLogin(ssoAuthConfig: any) {
    this.ssoLoginSpinner = true;
    this.inAppOauthService.login(ssoAuthConfig);
    localStorage.setItem('userinfo-ep', ssoAuthConfig.userInfoEndpoint);
    this.ssoLoginSpinner = false;
  }

}
