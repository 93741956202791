export const environment = {
  production: false,

  HOST: {
    API_URL: 'https://neo-hire-v2-xffvl2khoq-uk.a.run.app',
    HOST_API_URL: 'https://api.examly.net/api',
    FORMBUILDER_API_URL: 'https://neo-hire-form-builder-v2-xffvl2khoq-uk.a.run.app',
    NODE_ENV: 'acc',
    BULK_INVITE: 'https://neo-hire-bulk-invite-v2-xffvl2khoq-uk.a.run.app',
    SOCKET_LINK: 'wss://0i8kb3kr78.execute-api.us-east-1.amazonaws.com/dev'
  },
  ENCRYPTION: {
    ENCRYPTION_KEY: "J0RKHRnJTwRIqy5R"
  },
  CRISP_CHAT: {
    CRISP_WEBSITE_ID: "2e744b5d-16a2-42ba-86ac-35457af3372c",
  },
  FEEDBACK: {
    nstech196: {
      TECHNICAL: 'Technical Interview Feedback Form',
      FINAL: 'Wiley Edge - Final Interview Feedback Form'
    },
    kscollege408: {
      TECHNICAL: 'Technical Interview Feedback Form',
      FINAL: 'Wiley Edge - Final Interview Feedback Form'
    },
    wileyedge537: {
      TECHNICAL: 'Technical Interview Feedback Form',
      FINAL: 'Wiley Edge - Final Interview Feedback Form'
    },
    wileyedge399: {
      TECHNICAL: 'Technical Interview Feedback Form',
      FINAL: 'Wiley Edge - Final Interview Feedback Form'
    },
    iamneo372: {
      TECHNICAL: 'Technical Interview Feedback Form',
      FINAL: 'Final Interview Feedback Form'
    },
    srv1362: {
      TECHNICAL: 'Technical Feedback Form',
      MANAGER: 'Manager Feedback Form',
      FINAL: 'HR Feedback Form',
      TECHNICAL_MANAGEMENT: 'Technical Feedback Form - Management and Grads',
      MANAGER_MANAGEMENT: 'Manager Feedback Form - Management and Grads',
      FINAL_MANAGEMENT: 'HR Feedback Form - Management and Grads',
      HR_REVISED: 'HR-Feedback Revised Form',
      HR_FINAL_INTERVIEW: 'HR INTERVIEW FORM'
    }
  },
  favicon_Bucket: "dev-users-asset",
  AZURE: {
    CLIENT_ID: "94a0302a-0882-4a74-b60c-36f86fb200c7",
    TENANT_ID: "0f56a52d-fbe0-408f-8102-d7e194df2fa7"
  }
};