import { Component, ViewEncapsulation, OnInit, Input, OnChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { JobServicesService } from '../services/job-services.service';
import { GlobalServicesService } from '../services/global-services.service';

declare let tinymce: any;

@Component({
  selector: 'app-job-add',
  templateUrl: './job-add.component.html',
  styleUrls: ['./job-add.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessageService],
})
export class JobAddComponent implements OnInit, OnChanges {
  @Input() jobDetails: any;

  department: any[] = [];
  jobType: any[] = [];

  salaryRange: boolean = false;
  showAddDepartment: boolean = false;
  deptBtnLoading: boolean = false;
  saveBtnLoading: boolean = false;
  publishBtnLoading: boolean = false;
  invalidDetails: boolean = false;
  clicked: boolean = false;
  loadingSpinner: boolean = true;

  selectedDepartment: string = ''
  selectedJobType: string = ''
  newDepartmentName: string = '';
  minSalary: any;

  addJobForm: FormGroup = new FormGroup({
    jobName: new FormControl('', [Validators.required]),
    jobType: new FormControl('', [Validators.required]),
    department: new FormControl(this.selectedDepartment),
    salary: new FormControl(null, [Validators.required, Validators.min(0)]),
    minSalary: new FormControl(null, Validators.min(0)),
    maxSalary: new FormControl(null, (control: AbstractControl) => Validators.min(this.minSalary)(control)),
    location: new FormControl('', [Validators.required]),
    salaryRange: new FormControl(false),
    workExperience: new FormControl(null, Validators.min(0)),
    jobDescription: new FormControl(''),
  });

  constructor(private jobService: JobServicesService, private messageService: MessageService, private globalService: GlobalServicesService) {
    this.jobType = [
      { name: 'Part-Time' },
      { name: 'Full-Time' },
      { name: 'Internship' },
      { name: 'Contract' },
    ];
  }

  ngOnInit(): void {
    this.getDepartments();
    this.loadTinyMce();
  }

  ngOnChanges(): void {
    this.loadTinyMce();
    if (this.jobDetails.minSalary || this.jobDetails.maxSalary) this.salaryRange = true;
    if (this.jobDetails) {
      this.selectedDepartment = this.jobDetails.department;
      this.selectedJobType = this.jobDetails.jobType;
      this.addJobForm.patchValue(this.jobDetails);
      tinymce.activeEditor.setContent(this.jobDetails.jobDescription);
    }
  }

  getDepartments = () => {
    this.jobService.getSchool().subscribe(res => {
      this.department = res.data ? res.data[0].department : [];
    })
  }

  addDepartment = (id: any) => {
    let input: any = document.getElementById(id)
    this.newDepartmentName = input.value;
    if (this.newDepartmentName) {
      this.deptBtnLoading = true;
      let _department = this.department;
      _department.push({ name: this.newDepartmentName, code: this.newDepartmentName })
      let payload = { department: _department };
      this.jobService.createDepartment(payload).subscribe(response => {
        this.department = response.data.department ? response.data.department : [];
        this.deptBtnLoading = false;
        this.showAddDepartment = false;
      })
    }
  }

  changed(event: any){
    this.minSalary = event.value;
  }

  publishJob = () => {
    this.clicked = true;
    // setInterval(() => {  
    //   this.publishBtnLoading = false;
    // }, 2000);
    if (this.addJobForm.valid) {
      this.clicked = false;
      this.publishBtnLoading = true;
      // this.addJobForm.get('salary')?.addValidators(Validators.required);
      // this.addJobForm.get('minSalary')?.clearValidators;
      // this.addJobForm.get('maxSalary')?.clearValidators;
      let publishPayload = this.addJobForm.value;
      publishPayload.status = "Active";
      publishPayload.jobDescription = this.parseHtmlToText(publishPayload.jobDescription);
      publishPayload.jobDescription = tinymce.get(0).getContent()
        ? tinymce.get(0).getContent()
        : null;

      this.jobService.addJob(publishPayload).subscribe((res: any) => {
        if (Object.keys(res.data).length !== 0) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Job Saved Successfully' });
          this.addJobForm.reset();
        }
        else this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Unable to save Job' });
        this.publishBtnLoading = false;
      })
      // clearTimeout(this.publishBtnLoading)
    }

  }

  handleChange() {
    if (!this.addJobForm.value.salaryRange) {
      this.addJobForm.get('minSalary')?.setValue(null);
      this.addJobForm.get('maxSalary')?.setValue(null);
      this.addJobForm.get('salary')?.addValidators([Validators.required, Validators.min(0)]);
      this.addJobForm.get('minSalary')?.clearValidators();
      this.addJobForm.get('maxSalary')?.clearValidators();
      this.addJobForm.get('minSalary')?.updateValueAndValidity()
      this.addJobForm.get('maxSalary')?.updateValueAndValidity()
    } else {
      this.addJobForm.get('minSalary')?.addValidators([Validators.required,Validators.min(0)]);
      this.addJobForm.get('maxSalary')?.addValidators([Validators.required, (control: AbstractControl) => Validators.min(this.minSalary)(control)]);
      this.addJobForm.get('salary')?.setValue(null);
      this.addJobForm.get('salary')?.clearValidators();
      this.addJobForm.get('salary')?.updateValueAndValidity()
    }
  }

  // saveJob = (payload: FormGroup) => {
  //   this.saveBtnLoading = true;
  //   let savePayload = payload.value;
  //   if (!this.salaryRange) {
  //     delete savePayload.minSalary;
  //     delete savePayload.maxSalary;
  //   }
  //   if (this.salaryRange) {
  //     delete savePayload.salary;
  //   }
  //   savePayload.jobDescription = tinymce.get(0).getContent()
  //     ? tinymce.get(0).getContent()
  //     : null;

  //   savePayload.status = "Saved";
  //   this.jobService.addJob(savePayload).subscribe((res: any) => {
  //     if (Object.keys(res.data).length !== 0) {
  //       this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Job Saved Successfully' });
  //       this.addJobForm.reset();
  //     }
  //     else this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Unable to save Job' });
  //     this.saveBtnLoading = false;
  //   })

  // }

  // updateJob = (payload: FormGroup) => {
  //   this.saveBtnLoading = true;
  //   let updatePayload = payload.value;
  //   if (!this.salaryRange) {
  //     delete updatePayload.minSalary;
  //     delete updatePayload.maxSalary;
  //   }
  //   if (this.salaryRange) {
  //     delete updatePayload.salary;
  //   }
  //   updatePayload.jobDescription = tinymce.get(0).getContent()
  //     ? tinymce.get(0).getContent()
  //     : null;

  //   updatePayload.status = this.jobDetails.status;
  //   this.jobService.updateJob(updatePayload, this.jobDetails.id).subscribe((res: any) => {
  //     if (Object.keys(res.data).length !== 0) {
  //       this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Job Edited Successfully' });
  //     }
  //     else this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Unable to Edit Job' });
  //     this.saveBtnLoading = false;
  //   })
  // }

  parseHtmlToText = (text: string): string => {
    let parser = new DOMParser();
    let parsedHtml = parser.parseFromString(text, 'text/html');
    return parsedHtml.all[0].textContent || '';
  }

  async loadTinyMce() {
    await this.globalService.loadScript(
      'script',
      'https://cdn.tiny.cloud/1/r8fyjcvpxki8z6saolluylpci1u6mgcde4cm4rm3e1g8eiab/tinymce/5/tinymce.min.js',
      'text/javascript',
      'examlyck'
    );
    tinymce.remove();
    tinymce
      .init({
        selector: 'textarea',
        content_style:
          "@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap'); body { font-family: Poppins; }",
        height: 500,
        menu: '',
        plugins:
          'print preview paste searchreplace autolink ' +
          'directionality code visualblocks visualchars fullscreen link media ' +
          'codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists ' +
          'wordcount textpattern noneditable help charmap quickbars emoticons',
        toolbar:
          'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | ' +
          'alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | ' +
          'forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview print | ' +
          'insertfile quickimage media link anchor codesample | ltr rtl',
        toolbar_sticky: true,
        images_upload_url: '',
        image_caption: true,
        quickbars_selection_toolbar:
          'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
        noneditable_noneditable_class: 'mceNonEditable',
        toolbar_mode: 'wrap',
        contextmenu: 'link image imagetools table',
        relative_urls: false,
      })
      .then((editor: any) => { });
  }
}