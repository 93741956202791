<div class="emailTemplate-container">
    <div *ngIf="isLoading" class="loadingOverlay">
        <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
        </p-progressSpinner>
    </div>
    <div class="generalTemplate-header">
        <p>General Settings</p>
    </div>

    <div class="emailTemplate-content">
        <div class="categories">
            <div class="category" [ngClass]="{'active' : category.isActive}" *ngFor="let category of categories"
                (click)="openCategory(category.name)">
                <p>{{category.name}}</p>
            </div>
        </div>
        <div class="templates-container">
            <div *ngIf="activeComponent === 'Primary Account'">
                <app-primary-account></app-primary-account>
            </div>
            <div *ngIf="activeComponent === 'Company Details'">
                <app-company-details></app-company-details>
            </div>
            <div *ngIf="activeComponent === 'Branches'">
                <app-branches></app-branches>
            </div>
            <div *ngIf="activeComponent === 'Joining Cohort Fields'">
                <app-cohort-joining></app-cohort-joining>
            </div>
            <div *ngIf="activeComponent === 'Status'">
                <app-status></app-status>
            </div>

            <div *ngIf="activeComponent === 'Date and Time'">
                <app-date-time-settings></app-date-time-settings>
            </div>
            
            <div *ngIf="activeComponent === 'SSO Configuration'">
                <app-sso-credentials></app-sso-credentials>
            </div>

            <!-- <div *ngIf="activeComponent === 'Departments'">
                <app-departments></app-departments>
            </div> -->
        </div>
    </div>

    <p-toast></p-toast>
</div>