import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface SSOCredModel {
  appName: string;
  authEndpoint: string;
  clientId: string;
  responseType: string;
  redirectUri: string;
  scope: string;
}

@Injectable({
    providedIn: 'root'
})
export class InAppOauthService {

    apiUrl = environment.HOST.API_URL;
    
    storage: Storage = window.localStorage;

    constructor(private readonly http: HttpClient) {}

    login(ssoAuthConfig: SSOCredModel) {
        const params = new URLSearchParams();
        params.set('response_type', ssoAuthConfig.responseType);
        params.set('client_id', ssoAuthConfig.clientId);
        params.set('redirect_uri', ssoAuthConfig.redirectUri);
        params.set('scope', ssoAuthConfig.scope);
        params.set('nonce', this.generateNonce());
        params.set('state', this.generateNonce());
        window.location.href = `${ssoAuthConfig.authEndpoint}?${params.toString()}`;
    }

    generateNonce() {
      return Array.from(crypto.getRandomValues(new Uint8Array(16)), byte =>
        ('0' + byte.toString(16)).slice(-2)
      ).join('');
    }

    getUserInfo(idToken: string, userInfoEndpoint: string): Observable<any> {
      const schoolId = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
      : '';

      return this.http.post(this.apiUrl + '/userinfo', {}, {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + idToken,
          Origin: window.location.origin,
          userInfoEp: userInfoEndpoint,
          schoolId: schoolId,
        }),
      });
    }
}