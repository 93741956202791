<div *ngIf="isLoading" class="loadingOverlay">
    <p-progressSpinner [style]="{height: '30px', width: '30px'}" strokeWidth="7" animationDuration="0.5s">
    </p-progressSpinner>
</div>
<div class="pageInfo">
    <div style="display: flex;justify-content:space-between; align-items: center; width: 100%;">
        <div style="display: flex;justify-content:space-between; align-items: center;">
            <em class="pi pi-shield"></em>
            <p style="font-size: var(--fs-large); font-weight: var(--fw-bold);margin-left: 12.5px;">SSO Configuration
            </p>
        </div>
        <div *ngIf="true">
            <button style="height:38px; font-size:var(--fs-medium)" pButton pRipple type="submit" label="Add New"
                (click)="openAddTemplate()"></button>
        </div>
    </div>
</div>

<div class="info-card">
    <div style="padding:20px 20px 50px 20px" *ngFor="let sso of ssoData; index as i">
        <div class="border-radius_top"
            style="padding: 20px 20px 10px 20px; display: flex; justify-content: space-between;align-items: center; border:2px solid #DFE3E7">
            <p style="font-size: var(--fs-large);font-weight:var(--fw-semi-bold)">{{ sso.appName }}</p>
            <div>
                <em style="margin-right: 10px;padding:10px; background-color:  rgba(102, 102, 102, 0.05);border-radius: 4px; cursor: pointer"
                    class="pi pi-trash" (click)="deleteSsoDetail(i)"></em>
                <em style="margin: 10px;padding:10px;background-color:  rgba(102, 102, 102, 0.05);border-radius: 4px; cursor: pointer"
                    class="pi pi-pencil" (click)="editSsoDetail(i)"></em>
            </div>
        </div>
        <div class="border-radius_bottom" style="padding:20px;  
        border-left:   2px solid #DFE3E7;
        border-right:  2px solid #DFE3E7; 
        border-bottom: 2px solid #DFE3E7;
        font-size: var(--fs-medium)">
            <div style="display: flex;margin-bottom: 14px;">
                <em style="margin-right: 10px; color: var(--clr-primary);" class="pi pi-shield"></em>
                <p>Client Id : {{ sso.clientId }}</p>
            </div>
            <div style="display: flex;margin-bottom: 10px;">
                <em style="margin-right: 10px; color: var(--clr-primary);" class="pi pi-info-circle"></em>
                <p>Status : {{ sso.isActive ? 'Active' : 'Disabled' }}</p>
            </div>
        </div>
    </div>
</div>
<p-sidebar *ngIf="addTemplateSidebar" class="addTemplate-slider" [(visible)]="addTemplateSidebar"
    styleClass="p-sidebar-lg" [showCloseIcon]="true" position="right" (onHide)="sliderHide()">
    <div class="addTemplate-header">
        <div class="addTemplate-heading"><em style="margin-right: 12.5px;" class="pi pi-shield"></em>
            {{edit ? "Edit SSO Configuration" : "Add New SSO Configuration"}}
        </div>
        <div class="header-btn">
            <button pButton pRipple type="submit" label="Cancel" (click)='cancel()'
                class="p-button-outlined button-align save-btn"></button>
            <button pButton pRipple type="submit" label="Save" (click)="saveTemplate()"
                class="p-button-raised p-button-text button-align" [loading]="saveBtnLoading"></button>
        </div>
    </div>
    <p-divider class="p-divider"></p-divider>
    <div class="templateForm" [formGroup]="ssoDetails">

        <span class="elements-checkbox">
            <p-checkbox [binary]="true" inputId="binary" formControlName="isActive" label="Enable Configuration">
            </p-checkbox>
        </span>

        <span class="p-float-label templateForm-elements">
            <input class="inputtext" type="text" id="template-title" #name pInputText formControlName="appName"
                [ngClass]="{'p-filled': ssoDetails.value.appName}">
            <label for="template-title">App Name <span [style]="{color: '#E24A47'}"> *</span></label>
        </span>
        <span class="p-float-label templateForm-elements">
            <input class="inputtext" type="text" id="template-title" pInputText formControlName="issuer"
                [ngClass]="{'p-filled': ssoDetails.value.issuer}">
            <label for="template-title">Issuer <span [style]="{color: '#E24A47'}"> *</span></label>
        </span>
        <span class="p-float-label templateForm-elements">
            <input class="inputtext" type="text" id="template-title" pInputText formControlName="redirectUri"
                [ngClass]="{'p-filled': ssoDetails.value.redirectUri}">
            <label for="template-title">Redirect URI <span [style]="{color: '#E24A47'}"> *</span></label>
        </span>
        <span class="p-float-label templateForm-elements">
            <input class="inputtext" type="text" id="template-title" pInputText formControlName="clientId"
                [ngClass]="{'p-filled': ssoDetails.value.clientId}">
            <label for="template-title">Client ID <span [style]="{color: '#E24A47'}"> *</span></label>
        </span>
        <span class="p-float-label templateForm-elements">
            <input class="inputtext" type="text" id="template-title" pInputText formControlName="scope"
                [ngClass]="{'p-filled': ssoDetails.value.scope}">
            <label for="template-title">Scope <span [style]="{color: '#E24A47'}"> *</span></label>
            <p class="hints">Enter multiple values separated by a space</p>
        </span>
        <span class="p-float-label templateForm-elements">
            <input class="inputtext" type="text" id="template-title" pInputText formControlName="userInfoEndpoint"
                [ngClass]="{'p-filled': ssoDetails.value.userInfoEndpoint}">
            <label for="template-title">User Info Endpoint <span [style]="{color: '#E24A47'}"> *</span></label>
        </span>
        <span class="p-float-label templateForm-elements">
            <input class="inputtext" type="text" id="template-title" pInputText formControlName="authEndpoint"
                [ngClass]="{'p-filled': ssoDetails.value.authEndpoint}">
            <label for="template-title">Auth Endpoint <span [style]="{color: '#E24A47'}"> *</span></label>
        </span>
        <span class="p-float-label templateForm-elements">
            <input class="inputtext" type="text" id="template-title" pInputText formControlName="responseType"
                [ngClass]="{'p-filled': ssoDetails.value.responseType}">
            <label for="template-title">Response Type <span [style]="{color: '#E24A47'}"> *</span></label>
            <p class="hints">Enter multiple values separated by a space</p>
        </span>
    </div>
</p-sidebar>