import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LoginService {


  api_url = environment.HOST.API_URL;
  host_api_url = environment.HOST.HOST_API_URL;
  public validToken = new Subject<any>();
  public privilege: any = {};
  options() {
    const schoolId = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
      : '';
    const jobId = window.localStorage.getItem('selectedJobId') || '';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('token') ?? '',
      Origin: window.location.origin,
      schoolid: schoolId,
      jobId: jobId,
    });
    return { headers };
  }

  constructor(private http: HttpClient) { }

  login(payload: any): Observable<any> {
    return this.http.post(this.api_url + '/login', payload, this.options());
  }

  logout(): Observable<any> {
    return this.http.post(this.api_url + '/logout', {}, this.options());
  }

  isLoggedIn(email: string) {
    return this.http.get(this.api_url + '/login/' + email, this.options());
  }
  
}
