import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgOtpInputModule } from 'ng-otp-input';
import { GeneralSettingsComponent } from './general-settings/general-settings.component';

import { ChipModule } from 'primeng/chip';

import { SharedModule } from '../shared/shared.module';
import { BranchesComponent } from './branches/branches.component';
import { CohortJoiningComponent } from './cohort-joining/cohort-joining.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { DateTimeSettingsComponent } from './date-time-settings/date-time-settings.component';
import { DepartmentsComponent } from './departments/departments.component';
import { PrimaryAccountComponent } from './primary-account/primary-account.component';
import { SSOCredentialsComponent } from './sso-credentials/sso-credentials.component';
import { StatusComponent } from './status/status.component';

@NgModule({
  declarations: [
    GeneralSettingsComponent,
    PrimaryAccountComponent,
    CompanyDetailsComponent,
    BranchesComponent,
    DepartmentsComponent,
    CohortJoiningComponent,
    DateTimeSettingsComponent,
    StatusComponent,
    SSOCredentialsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgOtpInputModule,
    SharedModule,
    ChipModule,
  ]
})
export class SettingsModule { }
