import { Component, OnInit, ViewChild } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-sso-credentials',
  templateUrl: './sso-credentials.component.html',
  styleUrls: ['./sso-credentials.component.css'],
})
export class SSOCredentialsComponent implements OnInit {

  isLoading: boolean = false;
  saveBtnLoading: boolean = false;
  settingsSlider: boolean = false;
  stageTypes: any = [];
  userDetails: any = {};
  selectedStatusLabel: string = '';

  issuer: string = '';
  redirectUri: string = '';
  clientId: string = '';
  scope: string[] = [];
  schoolDetails: any;
  credentialsExist: boolean | undefined;

  constructor(private readonly messageService: MessageService, private readonly settingService: SettingsService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.userDetails = localStorage.getItem('userDetails')
      ? JSON.parse(localStorage.getItem('userDetails') || '')
      : '';
    this.schoolDetails = localStorage.getItem('schoolDetails')
      ? JSON.parse(localStorage.getItem('schoolDetails') || '')
      : '';
    this.getCredentials();
  }

  openStatusSettings() {
    this.settingsSlider = true;
  }

  getCredentials() {
    this.settingService.getSSOCredentials(this.schoolDetails[0].schoolId).subscribe(
      response => {
        this.isLoading = false;
        if (response?.data && response.data.length > 0) {
          const credentials = response.data[0];
          this.issuer = credentials.issuer || '';
          this.redirectUri = credentials.redirectUri || '';
          this.clientId = credentials.clientId || '';
          if (typeof credentials.scope === 'string') {
            this.scope = credentials.scope.split(',').map((item: string) => item.trim());
          } else {
            this.scope = credentials.scope || [];
          }
          if (this.issuer && this.clientId) {
            this.credentialsExist = true;
            this.stageTypes = [
              { label: 'Issuer', value: this.issuer },
              { label: 'Redirect URI', value: this.redirectUri },
              { label: 'Client ID', value: this.clientId },
              { label: 'Scope', value: this.scope.join(', ') }
            ];
          }
        } else {
          this.credentialsExist = false;
        }
        this.isLoading = false;
      },
      error => {
        console.error('Error fetching SSO credentials', error);
        this.isLoading = false;
      }
    );
  }

  saveStatus() {
    this.saveBtnLoading = true;
    const ssoCredentials = {
      issuer: this.issuer,
      redirectUri: this.redirectUri,
      clientId: this.clientId,
      scope: this.scope
    };

    const handleSuccess = () => {
      this.getCredentials();
      this.saveBtnLoading = false;
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'SSO credentials updated successfully!' });
      this.settingsSlider = false;
    }

    const handleError = () => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to update SSO credentials.' });
    }

    if (this.credentialsExist) {
      this.settingService.updateSSOCredentials(this.schoolDetails[0].schoolId, ssoCredentials).subscribe(handleSuccess, handleError);
    } else {
      this.settingService.saveSSOCredentials(ssoCredentials).subscribe(handleSuccess, handleError);
    }
  }
}
