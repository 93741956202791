import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { GlobalServicesService } from '../services/global-services.service';
import { InAppOauthService } from '../services/oauth.service';


@Component({
  selector: 'app-login-callback',
  templateUrl: './login-callback.component.html',
  styleUrls: ['./login-callback.component.css'],
})
export class LoginCallbackComponent implements OnInit {

  storage = window.localStorage;

  constructor(
    private messageService: MessageService,
    private globalService: GlobalServicesService,
    private inAppOauthService: InAppOauthService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    const userInfoEndpoint = this.storage.getItem('userinfo-ep')
    this.messageService.add({ severity: 'info', summary: 'Logging in...', detail: 'Logging in via SSO!', sticky: true });
    const fragment = window.location.hash.substring(1);
    const params = new URLSearchParams(fragment);
    const accessToken = params.get('access_token') || '';
    const idToken = params.get('id_token');
    if (accessToken && idToken && userInfoEndpoint) {
      this.handleCallback(accessToken, userInfoEndpoint);
    } else {
      this.messageService.add({ severity: 'error', summary: 'Login Failed', detail: 'Failed to login via SSO!' });
    }
  }
  
  ngOnDestroy(): void {
    this.messageService.clear();
  }

  handleCallback(idToken: string, userInfoEndpoint: string): any {
    this.inAppOauthService.getUserInfo(idToken, userInfoEndpoint).subscribe((info) => {
      const { userToken: token, email, name, id, userId, schoolId } = info.data.data;
      const userType = info.data.data.userType;
      let userDetails = { email, name, id, schoolId, userType };
      userDetails.id = info.data.data.userId;
      this.globalService.getUserPrivileges(userType).subscribe((response) => {
        if (response.status === 200) {
          localStorage.setItem('userPrivilege', response.data);
          localStorage.setItem('userDetails', JSON.stringify(userDetails));
          localStorage.setItem('token', token);
          this.router.navigate(['/events']);
        } else {
          console.error({ success: false, message: 'Something Went Wrong!' });
        }
      }, (error) => {
        console.error(error)
      });
    }, (error) => {
      console.error({ success: false, message: 'Failed to get user info', error: error });
    });
  }
}
