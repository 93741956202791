import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { JobServicesService } from '../../services/job-services.service';
import { RoleServicesService } from '../../services/role-services.service';
import { GlobalServicesService } from '../../services/global-services.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import * as locationList from '../../../assets/Json/locationsList.json'
import { nanoid } from 'nanoid'

declare let tinymce: any;

@Component({
  selector: 'app-role-add',
  templateUrl: './role-add.component.html',
  styleUrls: ['./role-add.component.css'],
  providers: [MessageService]
})
export class RoleAddComponent implements OnInit, OnChanges {
  @ViewChild('locationDiv') locationDiv: any;
  @ViewChild('roleTypeDiv') roleTypeDiv: any;
  @ViewChild('hiringManagerDiv') hiringManagerDiv: any;
  @Input() data: any;
  isUpdate: boolean = false;
  title = 'formarray';
  orderForm!: FormGroup;
  locationInformation!: FormArray;
  selectedRoleType: string = '';
  roleTypeList: any;
  resume: File[] = [];
  mediaFile: File[] = [];
  mediaBase64: any = '';
  loadingSpinner: boolean = true;
  clicked: boolean = false;
  hiringManager: any = [];
  locationList: any = (locationList as any).default;
  roleHeader: string = '';
  saveStatus: string = '';
  attach: any;
  roleDescriptionData: string = '';
  showEditor: boolean = false;
  saveBtnLoading: boolean = false;
  schoolId: string = '';
  uploadingFile: boolean = false;

  s3Domain: string = 'https://s3.amazonaws.com';
  bucketName: string = this.globalService.bucketName;

  constructor(
    private formBuilder: FormBuilder,
    private jobService: JobServicesService,
    private messageService: MessageService,
    private roleService: RoleServicesService,
    private globalService: GlobalServicesService,
    private fileUploadService: FileUploadService
  ) { }

  ngOnInit(): void {
    this.schoolId = localStorage.getItem('schoolData')
      ? JSON.parse(localStorage.getItem('schoolData') || '').school_id
      : '';
    this.globalService.removeScript();
    this.roleTypeList = [
      { label: 'FullTime', value: 'fulltime' },
      { label: 'PartTime', value: 'parttime' },
      { label: 'Intern', value: 'intern' },
      { label: 'Contract', value: 'contract' },
      { label: 'Other', value: 'other' },
    ];
    this.hiringManager = [];
    localStorage.setItem('owner', 'Ramesh');
    this.orderForm = new FormGroup({
      roleName: new FormControl('', Validators.required),
      requirement: new FormControl(''),
      roleType: new FormControl('', Validators.required),
      remoteJob: new FormControl(''),
      roleDescription: new FormControl(''),
      attach: new FormControl(''),
      locationInformation: new FormArray([]),
    });
    this.locationInformation = this.orderForm.get(
      'locationInformation'
    ) as FormArray;
    this.getHiringManager();
    (this.data && this.data.isUpdate) ? this.updateAction() : this.saveAction();
    this.loadTinyMce();
  }

  ngOnChanges(){
    this.mediaBase64 = this.data?.attach;
    let fileName = this.mediaBase64?.split('/').pop();
    let blob: any = new Blob([""], { type: 'application/pdf' });
    blob["lastModifiedDate"] = "";
    blob["name"] = fileName;
    this.mediaFile = [blob];
  }

  updateAction() {
    this.roleHeader = 'Update Role';
    this.saveStatus = 'Update';
  }

  saveAction() {
    this.roleHeader = 'Create New Role';
    this.saveStatus = 'Save';
  }

  setRoleDetails(data: any) {
    this.isUpdate = true;
    this.orderForm.patchValue({
      roleName: data.roleName,
      requirement: data.requirement,
      roleType: data.roleType,
      remoteJob: data.remoteJob,
      roleDescription: data.roleDescription,
      attach: data.attach,
      locationInformation: [],
    });
    this.selectedRoleType = data.roleType;
    data.roleDescription ? tinymce.activeEditor.setContent(data.roleDescription) : null;
    data.locationInformation.forEach((each: any) => {
      this.locationInformation.push(
        this.formBuilder.group({
          location: each.location ? each.location : '',
          hiringManager: each.hiringManager ? each.hiringManager : '',
          hiringManagerId: each.hiringManagerId ? each.hiringManagerId : '',
          businessUnit: each.businessUnit ? each.businessUnit : '',
          openings: each.openings ? each.openings : '',
          maximumApplicants: each.maximumApplicants
            ? each.maximumApplicants
            : '',
          maleDiversity: each.maleDiversity ? each.maleDiversity : '',
          femaleDiversity: each.femaleDiversity ? each.femaleDiversity : '',
        })
      );
    });
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      location: new FormControl('', Validators.required),
      hiringManager: new FormControl('', Validators.required),
      hiringManagerId: new FormControl('', Validators.required),
      businessUnit: '',
      openings: '',
      maximumApplicants: '',
      maleDiversity: '',
      femaleDiversity: '',
    });
  }

  addItem(): void {
    return this.locationInformation.push(this.createItem());
  }

  get formData() {
    return <FormArray>this.orderForm.get('locationInformation');
  }

  checkMandatory(data: any) {
    let success = true;
    if (data.roleName && data.roleType) {
      data.locationInformation.forEach((each: any) => {
        !(
          each &&
          each.location &&
          each.location.length &&
          each.hiringManager &&
          each.hiringManager.length
        ) && (success = false);
      });
    } else {
      success = false;
    }
    return success;
  }

  submitItem() {
    this.orderForm.value.owner = localStorage.getItem('owner');
    this.orderForm.value.attach = this.mediaFile[0] ? this.mediaBase64 : '';
    this.orderForm.value.roleType = this.orderForm.value.roleType.toLowerCase();
    this.orderForm.value.roleDescription = tinymce.get(0).getContent()
      ? tinymce.get(0).getContent()
      : null;
    if (this.checkMandatory(this.orderForm.value)) {
      if (this.isUpdate) {
        this.updateRoles();
      } else {
        this.addRoles();
      }
    } else {
      this.clicked = true;
      this.messageService.add({
        severity: 'error',
        summary: 'Validation Failed',
        detail: 'Please Fill Mandatory Fields',
      });
    }
  }

  updateRoles() {
    this.saveBtnLoading = true;
    this.roleService
      .updateRoles(this.data.id, this.orderForm.value)
      .subscribe((res: any) => {
        if (Object.keys(res.data).length) {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Role Update Successfully',
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Failed',
            detail: 'Unable to Update Role',
          });
        }
        this.saveBtnLoading = false;
      });
  }

  addRoles() {
    this.saveBtnLoading = true;
    this.roleService.addRoles(this.orderForm.value).subscribe((res: any) => {
      if (Object.keys(res.data).length) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Role Saved Successfully' });
        this.orderForm.reset();
        this.mediaBase64 = '';
        this.mediaFile = [];
        tinymce.activeEditor.setContent('');
        this.clicked = false;
      } else {
        this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Unable to Save Role' });
      }
      this.saveBtnLoading = false;
    });
  }

  removeItem(i: number) {
    this.locationInformation.removeAt(i);
  }

  handleUpload(event: any) {
    const file = event.addedFiles[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.fileUpload(file, event)
    };
  }

  fileUpload(file: any, event: any) {
    let fileName = file.name.split(".");
    let fileExtension = fileName.pop();
    fileName = `${fileName.join().replace(/\s/g, "").replace(/[^\w\s]/gi, '')}.${fileExtension}`;
    const pay = {
      Bucket_name: this.bucketName,
      file_name: `manage-job-roles/role-description-media/${this.schoolId}/${nanoid(10)}/${fileName}`,
      type: file.type,
    };

    const blobData: any = file;
    this.uploadingFile = true;
    this.fileUploadService.getSignedUrl(pay).subscribe(
      (url: any) => {
        if (url) {
          const json = url;
          this.fileUploadService.uploadUsingSignedUrl(
            json.data,
            blobData
          ).subscribe(
            (r: any) => {
              if (r && r.status === 200) {
                this.mediaBase64 = `https://s3.amazonaws.com/${pay.Bucket_name}/${pay.file_name}`;
                this.uploadingFile = false;    
                this.mediaFile = event.addedFiles;
              }
            }
          );
        }
      }
    );
  }

  onSelectMedia(event: any) {
    this.mediaFile.splice(this.mediaFile.indexOf(event), 1);
    this.handleUpload(event);
  }

  onRemoveMedia(event: any) {
    this.mediaFile.splice(this.mediaFile.indexOf(event), 1);
  }

  onSave() { }

  updateLocationDetails(key: any, index: number, value: any) {
    this.locationInformation.at(index).patchValue({
      [key]: value,
    });
  }

  updateHiringManagerDetails(key: any, index: number, value: any) {
    this.locationInformation.at(index).patchValue({
      [key[0]]: value.name,
      [key[1]]: value.id,
    });
  }

  getHiringManager() {
    this.loadingSpinner = true;
    this.jobService.getAllAccessMembers('hiringManager').subscribe((res) => {
      this.loadingSpinner = false;
      if (res) {
        res?.data?.items.forEach((each: any) => {
          this.hiringManager.push(each);
        });
      }
    });
  }

  async loadTinyMce() {
    await this.globalService.loadScript(
      'script',
      'https://cdn.tiny.cloud/1/r8fyjcvpxki8z6saolluylpci1u6mgcde4cm4rm3e1g8eiab/tinymce/5/tinymce.min.js',
      'text/javascript',
      'examlyck'
    );
    tinymce.remove();
    tinymce
      .init({
        selector: 'textarea',
        content_style:
          "@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap'); body { font-family: Poppins; }",
        height: 500,
        menu: '',
        plugins:
          'print preview paste searchreplace autolink ' +
          'directionality code visualblocks visualchars fullscreen link media ' +
          'codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists ' +
          'wordcount textpattern noneditable help charmap quickbars emoticons',
        toolbar:
          'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | ' +
          'alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | ' +
          'forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview print | ' +
          'insertfile quickimage link anchor codesample | ltr rtl',
        toolbar_sticky: true,
        images_upload_url: '',
        image_caption: true,
        quickbars_selection_toolbar:
          'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
        noneditable_noneditable_class: 'mceNonEditable',
        toolbar_mode: 'wrap',
        contextmenu: 'link image imagetools table',
        relative_urls: false
      })
      .then((editor: any) => {
        this.data
          ? this.setRoleDetails(this.data)
          : this.locationInformation.push(this.createItem());
        this.showEditor = true;
        this.loadingSpinner = false;
      }
      );
  }

  viewLocation()
  {
    setTimeout(() => {
      let el: any = this.locationDiv.nativeElement;
      el.click();
      }, 200);
  }
  
  viewRole()
  {
    setTimeout(() => {
      let el: any = this.roleTypeDiv.nativeElement;
      el.click();
      }, 200);
  }
  
  viewManager()
  {
    setTimeout(() => {
      let el: any = this.hiringManagerDiv.nativeElement;
      el.click();
      }, 200);
  }
  
}
