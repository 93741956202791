import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { nanoid } from 'nanoid';
import { MessageService } from 'primeng/api';
import { OtpService } from 'src/app/services/otp.service';
import { GlobalServicesService } from '../services/global-services.service';
import { LoginService } from '../services/login.service';
import { InAppOauthService, SSOCredModel } from '../services/oauth.service';
import { Router } from '@angular/router';
import { SettingsService } from '../services/settings.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [MessageService]
})
export class LoginComponent implements OnInit, OnChanges {
  @Input() companyLogo: string = '';


  passwordShow: boolean = false;
  height: string = window.innerHeight.toString() + 'px';
  isLoading: boolean = false;
  ssoSogenisLoading: boolean = false;
  emailPattern: any = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,24}$/;
  loginSection = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern(this.emailPattern)]),
    password: new FormControl('', [Validators.required, Validators.minLength(1)])
  });
  invalidCred: boolean = false;
  s3Domain: string = 'https://s3.amazonaws.com';
  bucketName: string = this.globalService.bucketName;
  sourceImg: string = `${this.s3Domain}/${this.bucketName}/general-settings/company-details/company-logo/default-logo.png`;
  coverImage: string = '';
  loginEmail: string = '';
  clicked: boolean = false;
  otpRequired: boolean = false;
  otpVerified: boolean = false;
  schoolData: any;
  schoolCode: any;
  routingDetails: any;
  userDetails: any;
  privilegeData: any;
  isSSOEnabled: boolean = false;
  ssoAuthConfig: any;

  constructor(
    private loginService: LoginService, 
    private router: Router,
    private globalService: GlobalServicesService, 
    private messageService: MessageService, 
    private otpService: OtpService,
    private inAppOauthService: InAppOauthService,
    private settingsService: SettingsService
    ) {
  }

  ngOnInit(): void {
    // get query params from url
    const queryParams = new URLSearchParams(window.location.search);
    this.isSSOEnabled = queryParams.get('sso') === 'true'; // temporary handle for sso enabled this has to be from database

    this.schoolData = localStorage.getItem('schoolData')
    ? JSON.parse(localStorage.getItem('schoolData') || '')
    : '';

    this.schoolCode = this.schoolData.school_code;
    if (this.isSSOEnabled) {
      this.fetchAndConfigureSSO();
    }

  }

  ngOnChanges(): void {

    this.coverImage = localStorage.getItem('schoolDetails')
    ? JSON.parse(localStorage.getItem('schoolDetails') || '')[0].company?.loginBanner
    : '';
    if(!this.coverImage && !this.coverImage?.length)
    {
      this.coverImage = `${this.s3Domain}/${this.bucketName}/general-settings/company-details/company-logo/default-cover.jpg`;
    }

    if (this.companyLogo.length) {
      this.sourceImg = this.companyLogo;
    }
  }

  onResize() {
    this.height = "100vh";
  }

  toggleShowHidePwd() {
    let x: any = document.getElementsByClassName("passwordInput")[0];
    if (x.type === "password") {
      x.type = "text";
      this.passwordShow = true;
    } else {
      x.type = "password";
      this.passwordShow = false;
    }
  }

  signIn() {
    this.clicked = true;
    this.loginSection.get('email')?.markAsTouched();
    this.loginSection.get('password')?.markAsTouched();
    if (this.loginSection.valid) {
      this.isLoading = true;
      let payload: any = {
        email: this.loginSection.value.email,
        password: this.loginSection.value.password
      }
      this.loginService.login(payload).subscribe((response) => {
        this.isLoading = false;
        let data = response.data;
        if (data && data !== 'Email or password is invalid') {
          let userDetails: any = {
            email: data.email,
            id: data.userId,
            name: data.userDetails.name,
          }
          this.userDetails = userDetails;
          let roleType: any = data.userDetails.userType;
          this.globalService.getUserPrivileges(roleType).subscribe((response) => {
            if (response.status === 200) {
              this.clicked = false;
              this.loginEmail = this.loginSection.value.email;
              let payload = {
                verificationType: '2FA',
              }
              this.otpService.sendOtp(this.loginEmail, nanoid(10), payload).subscribe((res: any) => {
                this.routingDetails = data;
                this.otpRequired = true;
                this.privilegeData = response.data;
              })
              // let allPrivileges = response.data;
              // localStorage.setItem("userPrivilege", allPrivileges);
              // this.router.navigateByUrl('/events');\
            }
            else {
              this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Something Went Wrong!' });
            }
          })
        }
        else {
          this.invalidCred = true;
        }
      })
    }
  }

  fetchAndConfigureSSO() {
    this.settingsService.getSSOCredentials(this.schoolData.school_id).subscribe(
      response => {
        if (response?.data && response.data.length > 0) {
          const credentials = response.data[0];
          this.ssoAuthConfig = {
            authEndpoint: credentials.authEndpoint,
            appName: credentials.appName,
            responseType: credentials.responseType,
            redirectUri: credentials.redirectUri,
            clientId: credentials.clientId,
            userInfoEndpoint: credentials.userInfoEndpoint,
            scope: credentials.scope.join(' '),
          };
          localStorage.setItem('userinfo-ep', JSON.stringify(this.ssoAuthConfig.userInfoEndpoint));
        }
      },
      error => {
        console.error('Error fetching SSO credentials', error);
      }
    );
  }

  socGenSignIn() {
    this.ssoSogenisLoading = true;
    this.inAppOauthService.login(this.ssoAuthConfig);
    this.ssoSogenisLoading = false;
  }

}