import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { GlobalServicesService } from './services/global-services.service';
import { SharedServicesService } from './services/shared-services.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { LoginService } from './services/login.service';
import { environment } from '../environments/environment';
import { MicrosoftAuthserviceService } from './services/microsoft-authservice.service';
import { SettingsService } from './services/settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [SharedServicesService, MessageService]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'webapp-neo-hire';
  headerTitle: string = '';
  secondTitle: string = '';
  route = '';
  applicationPage: boolean = false;
  registrationForm: boolean = false;
  routeListener: any;
  userDetails: any;
  authorized: boolean = false;
  searchItem: string = '';
  candidatePanel: boolean = false;
  companyLogo: string = '';
  favIcon: any;
  verification: boolean = false;
  storage: Storage = window.localStorage;


  constructor(
    private globalService: GlobalServicesService,
    private router: Router,
    private location: Location,
    private loginService: LoginService,
    private messageService: MessageService,
    private sharedService: SharedServicesService,
    private webTitle: Title,
    private ms_authservice: MicrosoftAuthserviceService,
    private settingsService: SettingsService
  ) {
    // SG Connect Redirection
    const fragment = window.location.hash.substring(1);
    const params = new URLSearchParams(fragment);
    const accessToken = params.get('access_token') ?? '';
    const idToken = params.get('id_token');
    if (accessToken && idToken) {
      this.storage.setItem('id_token', idToken);
      this.storage.setItem('access_token', accessToken); 
      this.navigateToLoginCallback(accessToken, idToken);
    }

    this.ms_authservice.configure();

    this.router.events.subscribe(() => {
      let nestedPaths = this.location.path().split('/')
      if (nestedPaths[1] === 'events' && nestedPaths.length === 2) {
        this.searchItem = 'Events';
      }
      else if (nestedPaths[1] === 'talentpool-management' && nestedPaths.length === 2) {
        this.searchItem = 'Candidate';
      }
      else {
        this.searchItem = '';
      }
      if (this.location.path() != '') {
        this.route = this.location.path();
        this.applicationPage = this.route.split('/')[1] === "application-form" ? true : false;
        this.candidatePanel = this.route.split('?')[0] === "/candidatePanel" ? true : false;
        this.verification = this.route.replace('?', '/').split('/')[1] === "verification" ? true : false;
        this.registrationForm = this.route.replace('?', '/').split('/')[1] === "registration-form" ? true : false;
      } else {
        this.route = ''
      }
    });

    this.sharedService.changeEmitted$.subscribe(res => {
      this.companyLogo = res;
      console.log(res)
    })
    this.favIcon = document.querySelector('#favIcon');
  }

  ngOnInit(): void {
    this.getSchoolData();

    this.loginService.validToken.subscribe((res: any) => {
      if (res.status === 401) {
        this.messageService.add(res.message);
        setTimeout(() => {
          this.router.navigateByUrl('/login');
        }, 5000)
      }
      if (res.statusMessage === 'loggedOut') {
        this.router.navigateByUrl('/login');
        window.location.reload();
      }
    })
    this.routeListener = this.router.events.subscribe(() => {
      if (this.location.path() != '') {
        this.route = this.location.path();
        this.applicationPage = this.route.split('/')[1] === "application-form" ? true : false;
      } else {
        this.route = ''
      }
    });
    this.isAuthorized();

  }

  navigateToLoginCallback(accessToken: string, idToken: string) {
    // navigate with hash instead of path to avoid full page reload
    this.router.navigate(['/login/callback'], {
      fragment: `access_token=${accessToken}&id_token=${idToken}`
    });
  }

  isLoginCallbackRoute(): boolean {
    return this.route === '/login/callback';
  }

  isAuthorized() {
    this.userDetails = localStorage.getItem('userDetails')
      ? JSON.parse(localStorage.getItem('userDetails') || '')
      : '';
    if (this.userDetails.email) {
    this.loginService.isLoggedIn(this.userDetails.email).subscribe(
    (res: any) => {
    if (res.status === 200) {
            this.authorized = true;
    }
        });
    }

  }

  getSchoolData() {
    this.globalService.getSchoolData().subscribe((schoolData: any) => {
      if (schoolData && schoolData.data) {
        localStorage.setItem('schoolData', JSON.stringify(schoolData.data));
        this.getSchoolDetails(schoolData.data);
      }
      this.setFavIcon();
      this.setWebTitle();
    });
  }

  getSchoolDetails(schoolData: any) {
    this.globalService.getSchoolDetails().subscribe((schoolDetails: any) => {
      if (schoolDetails && schoolDetails.data) {
        if(Array.isArray(schoolDetails.data)){
          let dateFor = schoolDetails.data[0].dateFormat;
          let timeFor = schoolDetails.data[0].timeFormat;
          let letter = ['D', 'Y'];
          schoolDetails.data[0].dateFormat = dateFor?.toString().replace( 
            new RegExp(letter.join('|'), 'g'),(match: string) => match.toLowerCase());
          schoolDetails.data[0].timeFormat = timeFor == '24 hours' ? 'HH:mm' : 'hh:mm';
        }
        localStorage.setItem('schoolDetails', JSON.stringify(schoolDetails.data));
         this.globalService.schoolData.next(true);
      }

      this.companyLogo = localStorage.getItem('schoolDetails')
        ? JSON.parse(localStorage.getItem('schoolDetails') || '')[0].company.companyLogo
        : '';
    })
  }

  setFavIcon() {
    let schoolData: any = localStorage.getItem("schoolData") ? localStorage.getItem("schoolData") : ""
    let bucket = environment.favicon_Bucket;
    let school_code = JSON.parse(schoolData).school_code
    if (schoolData) {
      this.favIcon.href = `https://${bucket}.s3.amazonaws.com/pwa/${school_code}/72X72.png`;
    }
  }

  setWebTitle() {
    let schoolData: any = localStorage.getItem("schoolData") ? localStorage.getItem("schoolData") : "Neo Hire"
    if (schoolData) {
      this.webTitle.setTitle(JSON.parse(schoolData).pwa_short_name);
    }
  }

  ngOnDestroy() {
    this.routeListener.unsubscribe();
  }
}