import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-sso-credentials',
  templateUrl: './sso-credentials.component.html',
  styleUrls: ['./sso-credentials.component.css'],
})

export class SSOCredentialsComponent implements OnInit {

  addTemplateSidebar: boolean = false;
  userInfo: any = [];
  ssoData: any = [];
  showPlaceholder: boolean = false;
  _templateTitle: string = '';
  saveBtnLoading: boolean = false;
  branchList: any;
  edit: boolean = false;
  clicked: boolean = false;
  isLoading: boolean = false;
  editIndex: any = -1;
  ssoDetails: FormGroup = new FormGroup({
    id: new FormControl(''),
    appName: new FormControl('', [Validators.required, Validators.maxLength(15)]),
    issuer: new FormControl('', Validators.required),
    isActive: new FormControl(true),
    redirectUri: new FormControl('', Validators.required),
    clientId: new FormControl('', Validators.required),
    scope: new FormControl('', Validators.required),
    userInfoEndpoint: new FormControl('', Validators.required),
    authEndpoint: new FormControl('', Validators.required),
    responseType: new FormControl('', Validators.required),
  })

  constructor(private settings: SettingsService, private messageService: MessageService) { }

  ngOnInit(): void {
    this.getData();
  }

  getData(){
    this.settings.getSsoDetails().subscribe((res: any) => {
      if (res.data) {
        if(res.status == 200){
          this.ssoData = res.data;
        }else{
          this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Something Went Wrong!', })
        }
      }
    })
  }

  sliderHide(){
    this.ssoDetails.reset(); 
    this.showPlaceholder = false;
    this.clicked = false;
    this.edit = false;
  }

  editSsoDetail(id: number) {
    this.edit = true;
    this.addTemplateSidebar = true
    this.ssoDetails.patchValue(this.ssoData[id]);
  }

  deleteSsoDetail(index:any) {
    this.isLoading = true;
    const id = this.ssoData[index].id;
    this.settings.deleteSsoDetails(id).subscribe((res:any)=>{
      if(res.status == 200){
        this.getData();
        this.isLoading = false;
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Deleted Successfully' });
      }
    })
  }

  openAddTemplate() {
    this.addTemplateSidebar = true;
  }

  cancel = () => {
    this.ssoDetails.reset();
    this.addTemplateSidebar = false;
    this.clicked = false;
    this.ssoDetails.reset();
    this.edit = false;
    this.saveBtnLoading = false;
  }

  saveTemplate() {
    this.clicked = true;
    this.saveBtnLoading = true;
    if (this.ssoDetails.valid) {
      if (this.edit) {
        const payload = this.ssoDetails.value;
        this.settings.updateSsoDetails(payload.id, payload).subscribe((res:any) => {
          this.saveBtnLoading = false;
          if(res.status == 200){
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Updated Successfully' });
            this.addTemplateSidebar = false
            this.clicked = false;
            this.ssoDetails.reset();
            this.edit = false;
            this.getData();
          }
        });
      } else {
        const payload = {
          appName: this.ssoDetails.value.appName,
          issuer: this.ssoDetails.value.issuer,
          isActive: this.ssoDetails.value.isActive,
          redirectUri: this.ssoDetails.value.redirectUri,
          clientId: this.ssoDetails.value.clientId,
          scope: this.ssoDetails.value.scope,
          userInfoEndpoint: this.ssoDetails.value.userInfoEndpoint,
          authEndpoint: this.ssoDetails.value.authEndpoint,
          responseType: this.ssoDetails.value.responseType
        };
        this.settings.addSsoDetails(payload).subscribe((res:any) => {
          this.saveBtnLoading = false;
          if(res.status == 200){
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Added Successfully' });
            this.addTemplateSidebar = false
            this.clicked = false;
            this.ssoDetails.reset();
            this.getData();
          }
        })
      }
    } else {
      const errors = this.ssoDetails.controls['appName'].errors;
      let detail = 'Please fill in all required fields.';
      if (errors) {
        const errorKeys = Object.keys(errors);
        console.log(errorKeys[0]);
        detail = errorKeys[0] === 'maxlength' ? 'Maximum length for App Name field cannot be more than 15 character.' : detail;
      }
      this.messageService.add({ severity: 'error', summary: 'Failed', detail });
      this.saveBtnLoading = false;
      this.getData();
    }
  }

}